import { environment } from '@environment/environment';

const BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL = `${environment.apiUrl}/maintenance-shutdown/import`;

const IMPORT = () => `${BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL}`;

const IMPORT_BATCH_RETURN = () => `${BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL}/actual-dates`;

const IMPORT_SHUTDOWN_ORDERS = () => `${BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL}/activity/orders`;

const IMPORT_ACTIVITIES_NOTES = () => `${BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL}/activity/notes`;

const IMPORT_ACTIVITIES_SYSTEMATICS = () =>
    `${BASE_MAINTENANCE_SHUTDOWN_IMPORTS_URL}/activity/systematics`;

export const MAINTENANCE_SHUTDOWN_IMPORTS_URL = {
    IMPORT,
    IMPORT_BATCH_RETURN,
    IMPORT_SHUTDOWN_ORDERS,
    IMPORT_ACTIVITIES_NOTES,
    IMPORT_ACTIVITIES_SYSTEMATICS,
};
