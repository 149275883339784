import { Injectable } from '@angular/core';
import { TypeIocFileExport } from '@app/core/utils/enums/ioc-type-file-export.enum';
import { OwnerOrAlternateOptionsEnum } from '@app/core/utils/enums/owner-or-alternate.enum';
import { WorkflowRolesEnum } from '@app/core/utils/enums/workflow-roles.enum';
import { WorkflowStatusEnum } from '@app/core/utils/enums/workflow-status.enum';
import { WorkflowTypesEnum } from '@app/core/utils/enums/workflow-types.enum';
import { OrdersStatus } from '@app/modules/portfolio/constants/orders-status.enum';
import { ProjectDemandRoleEnum } from '@app/modules/project-demand/models/project-demand-role.enum';
import { HasProjectDemandsEnum } from '@app/modules/shutdown-management/enums/has-project-demands.enum';
import { GeneralOptionsModel } from '../models/general-options.model';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class EnumUtilsService {
    constructor(private translateService: TranslateService) {}

    async getOwnerOrAlternateOptions(): Promise<GeneralOptionsModel[]> {
        const enumOptions = Object.keys(OwnerOrAlternateOptionsEnum).filter(option =>
            isNaN(Number(option))
        );

        const options: GeneralOptionsModel[] = [];
        for (const option of enumOptions) {
            options.push({
                name: await this.translateService.getTranslation(
                    `FILTER.OwnerOrAlternateOptions.${option}`
                ),
                code: OwnerOrAlternateOptionsEnum[option],
            });
        }

        return options;
    }

    async getBigShutdownOptions(): Promise<GeneralOptionsModel[]> {
        return [
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.BigShutdownOptions.All'
                ),
                code: null,
            },
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.BigShutdownOptions.OnlyBig'
                ),
                code: true,
            },
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.BigShutdownOptions.NotBig'
                ),
                code: false,
            },
        ];
    }

    async getMaintenanceShutdownStatusOptions(): Promise<GeneralOptionsModel[]> {
        return [
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.StatusOptions.All'
                ),
                code: 0,
            },
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.StatusOptions.Opened'
                ),
                code: 1,
            },
            {
                name: await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.StatusOptions.Canceled'
                ),
                code: 2,
            },
        ];
    }

    async getProjectDemandRoleOptions(): Promise<GeneralOptionsModel[]> {
        const enumOptions = Object.keys(ProjectDemandRoleEnum).filter(option =>
            isNaN(Number(option))
        );

        const options: GeneralOptionsModel[] = [];
        for (const option of enumOptions) {
            options.push({
                name: await this.translateService.getTranslation(
                    `FILTER.OwnerOrAlternateOptions.${option}`
                ),
                code: ProjectDemandRoleEnum[option].toString(),
            });
        }

        return options;
    }

    async getOrdersStatusOptions(): Promise<GeneralOptionsModel[]> {
        const ordersStatusNames = await this.translateService.getTranslation(
            'PORTFOLIO_PAGE.OrdersStatusOptions'
        );

        const options: GeneralOptionsModel[] = [];
        Object.keys(OrdersStatus)
            .filter(key => isNaN(+key))
            .forEach(status => {
                options.push({
                    name: ordersStatusNames[status],
                    code: OrdersStatus[status],
                });
            });

        return options;
    }

    async getTypeIocFileExportOptions(): Promise<GeneralOptionsModel[]> {
        const options: GeneralOptionsModel[] = [];
        Object.keys(TypeIocFileExport)
            .filter(key => isNaN(+key))
            .forEach(type => {
                options.push({
                    name: `MAINTENANCE.TypeFileToExport.${type}`,
                    code: TypeIocFileExport[type],
                });
            });

        return options;
    }

    async getHasProjectDemandsOptions(): Promise<GeneralOptionsModel[]> {
        const hasProjectDemandsOptions = await this.translateService.getTranslation(
            'SHUTDOWN_MANAGEMENT.HasProjectDemandsOptions'
        );

        const options: GeneralOptionsModel[] = [];
        Object.keys(HasProjectDemandsEnum)
            .filter(key => isNaN(+key))
            .forEach(option => {
                options.push({
                    name: hasProjectDemandsOptions[option],
                    code: HasProjectDemandsEnum[option],
                });
            });

        return options;
    }

    async getAutomaticEmailStatus(): Promise<GeneralOptionsModel[]> {
        return [
            {
                name: await this.translateService.getTranslation('GLOBAL.Active'),
                code: true,
            },
            {
                name: await this.translateService.getTranslation('GLOBAL.Inactive'),
                code: false,
            },
        ];
    }

    async getWorkflowRoles(): Promise<GeneralOptionsModel[]> {
        const workflowRoles = await this.translateService.getTranslation('WORKFLOW.Roles');

        const options: GeneralOptionsModel[] = [];
        Object.keys(WorkflowRolesEnum)
            .filter(key => isNaN(+key))
            .forEach(role => {
                options.push({
                    name: workflowRoles[role],
                    code: WorkflowRolesEnum[role],
                });
            });

        return options;
    }

    async getWorkflowStatus(): Promise<GeneralOptionsModel[]> {
        const workflowStatus = await this.translateService.getTranslation('WORKFLOW.Status');

        const options: GeneralOptionsModel[] = [];
        Object.keys(WorkflowStatusEnum)
            .filter(key => isNaN(+key))
            .forEach(status => {
                options.push({
                    name: workflowStatus[status],
                    code: WorkflowStatusEnum[status],
                });
            });

        return options;
    }

    async getWorkflowTypes(): Promise<GeneralOptionsModel[]> {
        const workflowTypes = await this.translateService.getTranslation('WORKFLOW.ChangeType');

        const options: GeneralOptionsModel[] = [];
        Object.keys(WorkflowTypesEnum)
            .filter(key => isNaN(+key))
            .forEach(type => {
                options.push({
                    name: workflowTypes[type],
                    code: WorkflowTypesEnum[type],
                });
            });

        return options;
    }

    async getBooleanOptions(): Promise<GeneralOptionsModel[]> {
        return [
            {
                name: await this.translateService.getTranslation('DEFAULT_TEXT.Yes'),
                code: true,
            },
            {
                name: await this.translateService.getTranslation('DEFAULT_TEXT.No'),
                code: false,
            },
        ];
    }
}
