@if (dataLoaded) {
    <div class="flex-container flex-column gap-16 tb-paginated">
        <div class="flex-container space-between align-items-end">
            <div>
                <ng-content select="#tableHeader"></ng-content>
            </div>
            @if (legends) {
                <app-global-color-legend [items]="legends"> </app-global-color-legend>
            }
            <div class="flex-container flex-item justify-end gap-16 personalized-container">
                @if (showCustomizeColumns) {
                    <app-customize-table-columns
                        [tableId]="tableId"
                        [tableColumns]="cols"
                        (columnsVisibilityChange)="
                            onColumnsVisibilityChange($event)
                        "></app-customize-table-columns>
                }
                @if (showExportTable) {
                    <button
                        pButton
                        pRipple
                        type="button"
                        [label]="'DEFAULT_TEXT.Export' | translate"
                        class="small ghost"
                        icon="pi pi-download"
                        (click)="exportFunction()"></button>
                }
            </div>
        </div>
        <p-table
            [id]="tableId"
            [value]="data"
            [scrollable]="true"
            [loading]="isLoading"
            styleClass="p-datatable-sm p-datatable-striped"
            [paginator]="true"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [(rows)]="filter.pageSize"
            [first]="(filter.pageNumber - 1) * filter.pageSize"
            [totalRecords]="filter.totalSize"
            [sortField]="filter.orderBy"
            [sortOrder]="filter.orderByAscending ? 1 : -1"
            [resetPageOnSort]="false"
            [columns]="cols"
            [reorderableColumns]="true"
            (onColReorder)="saveColumnsInCache($event.columns)"
            [resizableColumns]="resizable"
            columnResizeMode="expand"
            [lazy]="true"
            (onLazyLoad)="onLazyLoad($event)"
            [lazyLoadOnInit]="lazyLoadOnInit"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="'DEFAULT_TEXT.CurrentPageReport' | translate">
            <ng-template pTemplate="header">
                <tr>
                    @for (col of cols; track col) {
                        @if (col.data.visible) {
                            <th
                                id="{{ 'col_' + col.data.field }}"
                                [pTooltip]="col.data.header | translate"
                                tooltipPosition="top"
                                [pSortableColumn]="col.data.field"
                                [pSortableColumnDisabled]="!col.data.sortable"
                                pReorderableColumn
                                pResizableColumn
                                [pReorderableColumnDisabled]="!col.data.reorderable"
                                pFrozenColumn
                                [frozen]="
                                    applyFirstStickyColumnClass(col) || col.data.isFrozenColumn
                                "
                                [ngClass]="{
                                    'table-col-types-date-range':
                                        col.data.type === TableColTypes.DateRange,
                                    center: col.data.type === TableColTypes.Toggle,
                                    'no-resizable': !resizable
                                }">
                                @if (col.data.reorderable && showReorderableIcon) {
                                    <img
                                        src="../../../../../assets/images/ic-out-ordenation.svg"
                                        class="reorderable-icon" />
                                }
                                @if (col.data.type !== TableColTypes.Selection) {
                                    <span>{{ col.data.header | translate }}</span>
                                }
                                @if (col.data.type === TableColTypes.Selection) {
                                    <p-triStateCheckbox
                                        [disabled]="!canEdit"
                                        [(ngModel)]="allSelected"
                                        checkboxFalseIcon="pi pi-minus"
                                        (onChange)="selectAll()">
                                    </p-triStateCheckbox>
                                }
                                @if (col.data.sortable) {
                                    <p-sortIcon [field]="col.data.field"></p-sortIcon>
                                }
                                @if (col.data.type === TableColTypes.Remove) {
                                    <button
                                        p-button
                                        [disable]="!canEdit"
                                        (click)="onRemoveAll()"
                                        class="ghost gray pi pi-minus-circle"></button>
                                }
                            </th>
                        }
                    }
                </tr>
                @if (showFilterRow) {
                    <tr>
                        @for (col of cols; track col) {
                            @if (col.data.visible) {
                                <th
                                    pFrozenColumn
                                    [frozen]="
                                        applyFirstStickyColumnClass(col) || col.data.isFrozenColumn
                                    ">
                                    @if (
                                        commonFilterTypes?.includes(col.data.type) &&
                                        !col.data.dropdownFilterOptions
                                    ) {
                                        <p-columnFilter
                                            [type]="columnFilterType(col.data.type)"
                                            matchMode="contains"
                                            [field]="col.data.field"
                                            [showMenu]="false"
                                            [showClearButton]="false"></p-columnFilter>
                                    }
                                    @if (col.data.type === TableColTypes.NumberWithMenuFilter) {
                                        <span>{{
                                            getFilterLabel(col.data.field) | translate
                                        }}</span>
                                        <p-columnFilter
                                            [showOperator]="false"
                                            type="numeric"
                                            [field]="col.data.field"
                                            display="menu"
                                            [matchModeOptions]="matchModeOptions" />
                                    }
                                    @if (col.data.type === TableColTypes.DateWithRangeFilter) {
                                        <span>{{
                                            getFilterLabel(col.data.field) | translate
                                        }}</span>
                                        <p-columnFilter
                                            [showOperator]="false"
                                            type="date"
                                            [field]="col.data.field"
                                            display="menu"
                                            [matchModeOptions]="matchModeOptions">
                                            <ng-template
                                                pTemplate="filter"
                                                let-value
                                                let-filterConstraint="filterConstraint">
                                                <p-calendar
                                                    #calendar
                                                    [ngModel]="value"
                                                    (onSelect)="
                                                        dateFilterChange(
                                                            calendar.value,
                                                            filterConstraint
                                                        )
                                                    "
                                                    (onInput)="
                                                        dateFilterChange(
                                                            calendar.value,
                                                            filterConstraint
                                                        )
                                                    "></p-calendar>
                                            </ng-template>
                                        </p-columnFilter>
                                    }
                                    @if (booleanFilterTypes?.includes(col.data.type)) {
                                        <p-columnFilter
                                            matchMode="equals"
                                            [field]="col.data.field"
                                            [showMenu]="false"
                                            [showClearButton]="false"
                                            ><ng-template
                                                pTemplate="filter"
                                                let-value
                                                let-filter="filterCallback">
                                                <app-dropdown
                                                    [drodpwonId]="col.field + 'Filter'"
                                                    [ngModel]="value"
                                                    [options]="booleanOptions"
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    [placeholder]="'GLOBAL.All' | translate"
                                                    [small]="true"
                                                    (valueChange)="filter($event)">
                                                </app-dropdown> </ng-template
                                        ></p-columnFilter>
                                    }
                                    @if (col.data.dropdownFilterOptions) {
                                        <p-columnFilter
                                            matchMode="equals"
                                            [field]="col.data.field"
                                            [showMenu]="false"
                                            [showClearButton]="false"
                                            ><ng-template
                                                pTemplate="filter"
                                                let-value
                                                let-filter="filterCallback">
                                                <app-dropdown
                                                    [drodpwonId]="col.field + 'Filter'"
                                                    [ngModel]="value"
                                                    [options]="
                                                        col.data.dropdownFilterOptions | async
                                                    "
                                                    optionLabel="name"
                                                    optionValue="name"
                                                    [placeholder]="'GLOBAL.All' | translate"
                                                    [small]="true"
                                                    (valueChange)="filter($event)">
                                                </app-dropdown> </ng-template
                                        ></p-columnFilter>
                                    }
                                </th>
                            }
                        }
                    </tr>
                }
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr class="list-item">
                    @for (col of cols; track col) {
                        @if (col.data.visible) {
                            <td
                                id="{{ 'body_' + col.data.field }}"
                                [class]="
                                    'bg-' + backgroundColorFunction({ item, field: col.data.field })
                                "
                                pFrozenColumn
                                [frozen]="
                                    applyFirstStickyColumnClass(col) || col.data.isFrozenColumn
                                "
                                [ngClass]="{
                                    'table-col-types-date-range':
                                        col.type === TableColTypes.DateRange,
                                    center: col.type === TableColTypes.Toggle,
                                    canceled: canceledStyle && canceledStyle(item),
                                    'no-resizable': !resizable,
                                    'text-align-top': hasLargeTextColumn()
                                }"
                                [pTooltip]="formatTooltip(item, col) | translate"
                                tooltipPosition="top">
                                @switch (col.data.type) {
                                    @case (TableColTypes.DropdownMenu) {
                                        <p-menu
                                            #menu
                                            [model]="dropdownActions"
                                            [popup]="true"
                                            appendTo="body">
                                            <ng-template pTemplate="item" let-action>
                                                @if (
                                                    !action.displayFunction ||
                                                    action.displayFunction(item)
                                                ) {
                                                    <a
                                                        class="p-menuitem-link flex justify-content-between align-items-center p-3"
                                                        (click)="action.callback(item)">
                                                        <span
                                                            class="material-icons p-menuitem-icon"
                                                            >{{ action.icon }}</span
                                                        >
                                                        <span> {{ action.label }}</span>
                                                    </a>
                                                }
                                            </ng-template>
                                        </p-menu>
                                        <p-button
                                            class="menu-trigger"
                                            [link]="true"
                                            (click)="menu.toggle($event)"
                                            icon="pi pi-ellipsis-v"></p-button>
                                    }

                                    @case (TableColTypes.Selection) {
                                        <p-checkbox
                                            [(ngModel)]="selectedRows[item[propertySelectKey]]"
                                            [binary]="true"
                                            (onChange)="selectItem(item, $event.checked)"
                                            [disabled]="
                                                (isRowSelectable && !isRowSelectable(item)) ||
                                                !canEdit
                                            ">
                                        </p-checkbox>
                                    }

                                    @case (TableColTypes.Remove) {
                                        <button
                                            mat-button
                                            [disable]="!canEdit"
                                            (click)="onRemoveItem(item)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    }

                                    @case (TableColTypes.Toggle) {
                                        <p-checkbox
                                            [(ngModel)]="item[col.data.field]"
                                            [binary]="true"
                                            (onChange)="
                                                col.data.callbackOnClick({
                                                    item,
                                                    field: col.data.field
                                                })
                                            "
                                            [disabled]="
                                                (col.data.allowAction !== undefined
                                                    ? !col.data.allowAction(item)
                                                    : false) || !canEdit
                                            ">
                                        </p-checkbox>
                                    }

                                    @case (TableColTypes.Bookmark) {
                                        <button
                                            pButton
                                            pRipple
                                            type="button"
                                            [icon]="
                                                item[col.data.field]
                                                    ? 'pi pi-bookmark-fill'
                                                    : 'pi pi-bookmark'
                                            "
                                            class="p-button-rounded p-button-text"
                                            (click)="
                                                col.data.callbackOnClick({
                                                    item,
                                                    field: col.data.field
                                                })
                                            "></button>
                                    }

                                    @case (TableColTypes.Show) {
                                        <button
                                            pButton
                                            pRipple
                                            type="button"
                                            icon="pi pi-search"
                                            class="p-button-rounded p-button-text"
                                            (click)="
                                                col.data.callbackOnClick({
                                                    item,
                                                    field: col.data.field
                                                })
                                            "></button>
                                    }

                                    @case (
                                        col.data.type === TableColTypes.Date ||
                                        col.data.type === TableColTypes.DateWithRangeFilter
                                            ? col.data.type
                                            : ''
                                    ) {
                                        {{
                                            item[col.data.field]
                                                | date: ('primeng.dateTimeFormatGrid' | translate)
                                        }}
                                    }

                                    @case (TableColTypes.Shift) {
                                        {{ item[col.data.field] | shiftDuration | async }}
                                    }

                                    @case (TableColTypes.Boolean) {
                                        {{ item[col.data.field] | boolean | async }}
                                    }

                                    @case (TableColTypes.DateRange) {
                                        {{ getDateRangeString(item[col.data.field]) }}
                                    }

                                    @case (TableColTypes.LargeText) {
                                        <span class="large-text-cell">{{
                                            item[col.data.field]
                                        }}</span>
                                    }

                                    @case (TableColTypes.Percentage) {
                                        {{
                                            item[col.data.field] !== null
                                                ? item[col.data.field].toFixed(
                                                      col.data.fixedDigits
                                                  ) + '%'
                                                : '-'
                                        }}
                                    }

                                    @case (TableColTypes.ChipTextBoolean) {
                                        <span
                                            [class]="
                                                'bg-' +
                                                spanBackgroundColorFunction({
                                                    item,
                                                    field: col.data.field
                                                }) +
                                                ' chipField'
                                            "
                                            >{{ item[col.data.field] | boolean | async }}</span
                                        >
                                    }

                                    @case (TableColTypes.ChipText) {
                                        <span
                                            [class]="
                                                'bg-' +
                                                spanBackgroundColorFunction({
                                                    item,
                                                    field: col.data.field
                                                }) +
                                                ' chipField'
                                            "
                                            >{{ item[col.data.field] }}</span
                                        >
                                    }

                                    @case (TableColTypes.Percentage) {
                                        {{
                                            item[col.data.field] !== null
                                                ? item[col.data.field].toFixed(
                                                      col.data.fixedDigits
                                                  ) + '%'
                                                : '-'
                                        }}
                                    }

                                    @case (TableColTypes.TruncateLargeText) {
                                        {{
                                            item[col.data.field] !== null
                                                ? item[col.data.field].length > 30
                                                    ? (item[col.data.field] | slice: 0 : 30) + '...'
                                                    : item[col.data.field]
                                                : ''
                                        }}
                                    }

                                    @default {
                                        {{ item[col.data.field] }}
                                    }
                                }
                            </td>
                        }
                    }
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [colSpan]="cols.length">
                        {{ 'DEFAULT_TEXT.NoData' | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
}
