import { Injectable } from '@angular/core';
import { MAINTENANCE_SHUTDOWN_EXPORTS_URL } from '@app/core/constants/urls/maintenance-shutdown-exports.url';
import { MAINTENANCE_SHUTDOWN_IMPORTS_URL } from '@app/core/constants/urls/maintenance-shutdown-imports.url';
import { MAINTENANCE_SHUTDOWN_URL } from '@app/core/constants/urls/maintenance-shutdown.url';
import { CoiExportModel } from '@app/modules/shutdown-management/components/list/components/shutdown-management-export-ioc-modal/models/export-coi.model';
import { ApiResult } from '@app/shared/models/api-result.model';
import { CanCancelResponseModel } from '@app/shared/models/can-cancel-shutdown-response.model';
import { GanttResponse } from '@app/shared/models/gantt.model';
import {
    AcitivityNotesImportModel,
    ActivitiesSystematicsImportModel,
    ImportActivitiesResponseModel,
    ImportShutdownOrdersResponseModel,
    ImportStatusModel,
    ShutdownOrdersImportModel,
} from '@app/shared/models/import.model';
import { StatusModel } from '@app/shared/models/project-demand.model';
import { ReasonData } from '@app/shared/models/reason-data.model';
import { ReasonDataModel } from '@app/shared/models/reason.model';
import { ShutdownInfoModel } from '@app/shared/models/shutdown-info.model';
import { ShutdownManagementBatchReturnImportModel } from '@app/shared/models/shutdown-management-batch-return.model';
import {
    ShutdownManagementFilter,
    ShutdownManagementFilterExportModel,
    ShutdownManagementHistoryFilter,
} from '@app/shared/models/shutdown-management-filter.model';
import {
    JustificationCheckModel,
    MaintenanceShutdownFormResponseModel,
    ShutdownManagementFormModel,
    ShutdownStatusCheckModel,
} from '@app/shared/models/shutdown-management-form.model';
import {
    ChangeHistoryModel,
    NonAdherenceHistoryModel,
    OwnerHistoryModel,
} from '@app/shared/models/shutdown-management-history.model';
import { ShutdownMaintenanceListModel } from '@app/shared/models/shutdown-management-list.model';
import {
    ShutdownPlanningInfoModel,
    ShutdownPlanningModel,
} from '@app/shared/models/shutdown-management-overview.model';
import { ProjectDemandModel } from '@app/shared/models/shutdown-management-project-demand.model';
import {
    RequestorModel,
    ShutdownManagementExportModel,
    ShutdownManagementImportModel,
} from '@app/shared/models/shutdown-management.model';
import { UpdateOwnerModel } from '@app/shared/models/update-owner.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { HttpClient } from '@utils/helpers/HttpClient';
import { Observable, catchError, map, take } from 'rxjs';
import { TableDataUtilsService } from '../table-data-utils.service';
import { TranslateService } from '../translate.service';

@Injectable({
    providedIn: 'root',
})
export class ShutdownManagementService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService,
        private tableDataUtilsService: TableDataUtilsService
    ) {}

    private prepareAdditionalFilters(filter: ShutdownManagementFilter): ShutdownManagementFilter {
        if (filter.filterRow) {
            Object.entries(filter.filterRow).map(([key, value]) => {
                if (filter.additionalFilters === undefined) filter.additionalFilters = {};
                filter.additionalFilters[key] = value.value?.toString();
            });
        }
        return filter;
    }

    cancel(id: number, justificative: string, canceledBy: string): Observable<boolean> {
        return this.http
            .put(MAINTENANCE_SHUTDOWN_URL.CANCEL(id), { id, justificative, canceledBy }, {})
            .pipe(map((response: ApiResult<boolean>) => response.data))
            .pipe(take(1));
    }

    delete(id: number, justificative: string): Observable<ApiResult<boolean>> {
        return this.http
            .delete(MAINTENANCE_SHUTDOWN_URL.DELETE(id, justificative), {})
            .pipe(take(1));
    }

    getMaintenanceShutdownByFilter(
        filter: ShutdownManagementFilter
    ): Observable<ApiResult<ShutdownMaintenanceListModel[]>> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_URL.GET_BY_FILTER(), this.prepareAdditionalFilters(filter), {
                headers: this.tableDataUtilsService.setGridOptionsHeaders(filter),
            })
            .pipe(catchError(err => this.tableDataUtilsService.managePaginatedTableDataError(err)))
            .pipe(map((response: ApiResult<ShutdownMaintenanceListModel[]>) => response))
            .pipe(take(1));
    }

    getMaintenanceShutdownById(
        maintenanceShutdownId: Number
    ): Observable<MaintenanceShutdownFormResponseModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_BY_ID(maintenanceShutdownId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<MaintenanceShutdownFormResponseModel>) => response.data))
            .pipe(
                map((model: MaintenanceShutdownFormResponseModel) =>
                    this.castMaintenanceShutdownFormResponseModel(model)
                )
            )
            .pipe(take(1));
    }

    checkJustification(
        shutdownId: number,
        startDate: string,
        endDate: string
    ): Observable<JustificationCheckModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.CHECK_JUSTIFICATION(shutdownId, startDate, endDate))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<JustificationCheckModel>) => response.data))
            .pipe(take(1));
    }

    checkStatus(
        shutdownId: number,
        startDate: string,
        endDate: string,
        actualStartDate: string,
        actualEndDate: string
    ): Observable<ShutdownStatusCheckModel> {
        return this.http
            .get(
                MAINTENANCE_SHUTDOWN_URL.CHECK_STATUS(
                    shutdownId,
                    startDate,
                    endDate,
                    actualStartDate,
                    actualEndDate
                )
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownStatusCheckModel>) => response.data))
            .pipe(take(1));
    }

    getNonAdherenceJustificativeReason(): Observable<ReasonDataModel[]> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_NON_ADHERENCE_JUSTIFICATIVE_REASON())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ReasonDataModel[]>) => response.data))
            .pipe(take(1));
    }

    getAllAdherenceStatus(): Observable<StatusModel[]> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.LIST_ADHERENCE_STATUS())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<StatusModel[]>) => response.data))
            .pipe(take(1));
    }

    getRequestorList(withOthersOption?: boolean): Observable<RequestorModel[]> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_REQUESTOR_LIST(withOthersOption))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<RequestorModel[]>) => response.data))
            .pipe(take(1));
    }

    toggleIsBigShutdown(maintenanceShutdownId: number): Observable<boolean> {
        return this.http
            .put(MAINTENANCE_SHUTDOWN_URL.TOGGLE_BIG_SHUTDOWN(maintenanceShutdownId), {}, {})
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<boolean>) => response.data))
            .pipe(take(1));
    }

    exportMaintenanceShutdownListXlsx(
        filter: ShutdownManagementFilter
    ): Observable<ShutdownManagementExportModel> {
        return this.http
            .post(
                MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_LIST(),
                this.prepareAdditionalFilters(filter)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    exportMaintenanceShutdownListXlsxImportedWithErrors(hashFile: string): Observable<string> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_LIST_IMPORTED_WITH_ERRORS(hashFile))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<string>) => response.data))
            .pipe(take(1));
    }

    exportOrdersLayoutXlsx(): Observable<ShutdownManagementExportModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_ORDERS_LAYOUT_MODEL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    exportDefaultImportModelXlsx(): Observable<ShutdownManagementExportModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_DEFAULT_IMPORT_MODEL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    exportMaintenanceShutdownListReportXlsx(
        filter: ShutdownManagementFilter
    ): Observable<ShutdownManagementExportModel> {
        return this.http
            .post(
                MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_LIST_REPORT(),
                this.prepareAdditionalFilters(filter)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    exportBatchReturn(
        filter: ShutdownManagementFilterExportModel
    ): Observable<ShutdownManagementExportModel> {
        return this.http
            .post(
                MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_BATCH_RETURN(),
                this.prepareAdditionalFilters(filter)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    getGantt(filter: ShutdownManagementFilter): Observable<GanttResponse> {
        return this.http
            .get<ApiResult<GanttResponse>>(MAINTENANCE_SHUTDOWN_URL.GET_GANTT(filter))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<GanttResponse>) => response.data))
            .pipe(take(1));
    }

    getCalendar(filter: ShutdownManagementFilter): Observable<ShutdownMaintenanceListModel[]> {
        return this.http
            .post(
                MAINTENANCE_SHUTDOWN_URL.GET_BY_FILTER_CALENDAR(),
                this.prepareAdditionalFilters(filter)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownMaintenanceListModel[]>) => response.data))
            .pipe(take(1));
    }

    addMaintenanceShutdown(maintenanceShutdown: ShutdownManagementFormModel): Observable<void> {
        maintenanceShutdown = this.castShutdownManagementFormModel(maintenanceShutdown);

        return this.http
            .post(MAINTENANCE_SHUTDOWN_URL.ADD(), maintenanceShutdown)
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    exportCoi(data: CoiExportModel): Observable<ShutdownManagementExportModel> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_EXPORTS_URL.COI_EXPORT(), data)
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    updateMaintenanceShutdown(maintenanceShutdown: ShutdownManagementFormModel): Observable<void> {
        maintenanceShutdown = this.castShutdownManagementFormModel(maintenanceShutdown);

        return this.http
            .put(MAINTENANCE_SHUTDOWN_URL.UPDATE(maintenanceShutdown.id), maintenanceShutdown, {})
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    exportOrders(filter: ShutdownManagementFilter): Observable<ShutdownManagementExportModel> {
        return this.http
            .post(
                MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_ORDERS(),
                this.prepareAdditionalFilters(filter)
            )
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    importXlsxFile(xlsxFileData: ShutdownManagementImportModel): Observable<ImportStatusModel[]> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_IMPORTS_URL.IMPORT(), xlsxFileData)
            .pipe(catchError(err => this.showError(err)))
            .pipe(map((response: ApiResult<ImportStatusModel[]>) => response.data))
            .pipe(take(1));
    }

    importBatchReturn(
        xlsxFileData: ShutdownManagementBatchReturnImportModel
    ): Observable<ImportStatusModel[]> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_IMPORTS_URL.IMPORT_BATCH_RETURN(), xlsxFileData, {})
            .pipe(catchError(async err => this.showError(err)))
            .pipe(map((response: ApiResult<ImportStatusModel[]>) => response.data))
            .pipe(take(1));
    }

    updateOwner(newOwnerModel: UpdateOwnerModel): Observable<ApiResult<void>> {
        return this.http
            .patch(MAINTENANCE_SHUTDOWN_URL.PATCH_OWNER(), { ...newOwnerModel }, {})
            .pipe(catchError(err => this.notifyValidationErrorAndThrow(err)))
            .pipe(take(1));
    }

    getUpdateOwnerReasons(): Observable<ReasonData[]> {
        return this.http
            .get<ApiResult<ReasonData[]>>(MAINTENANCE_SHUTDOWN_URL.GET_UPDATE_OWNER_REASONS())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ReasonData[]>) => response.data))
            .pipe(take(1));
    }

    getOwnerHistory(
        filter: ShutdownManagementHistoryFilter
    ): Observable<ApiResult<OwnerHistoryModel[]>> {
        const headers = {
            pageNumber: `${filter.pageNumber}`,
            pageSize: `${filter.pageSize}`,
            orderBy: filter.orderBy,
            orderByAscending: `${filter.orderByAscending}`,
        };

        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_OWNER_HISTORY(filter), { headers: headers })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<OwnerHistoryModel[]>) => response))
            .pipe(take(1));
    }

    getChangeHistory(
        filter: ShutdownManagementHistoryFilter
    ): Observable<ApiResult<ChangeHistoryModel[]>> {
        const headers = {
            pageNumber: filter.pageNumber.toString(),
            pageSize: filter.pageSize.toString(),
            orderBy: filter.orderBy,
            orderByAscending: `${filter.orderByAscending}`,
        };

        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_CHANGE_HISTORY(filter), { headers: headers })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ChangeHistoryModel[]>) => response))
            .pipe(take(1));
    }

    getJustificatives(maintenanceShutdownId: number): Observable<NonAdherenceHistoryModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_URL.GET_JUSTIFICATIVES(maintenanceShutdownId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<NonAdherenceHistoryModel>) => response.data))
            .pipe(take(1));
    }

    importShutdownOrders(
        shutdownOrdersToImport: ShutdownOrdersImportModel
    ): Observable<ImportShutdownOrdersResponseModel> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_IMPORTS_URL.IMPORT_SHUTDOWN_ORDERS(), shutdownOrdersToImport)
            .pipe(
                catchError(err => {
                    return this.systemMessageService.notifyError(err.error.errors[0].message);
                })
            )
            .pipe(map((response: ApiResult<ImportShutdownOrdersResponseModel>) => response.data))
            .pipe(take(1));
    }

    setPlannedOrders(shutdownInfo: ShutdownPlanningInfoModel): Observable<ShutdownPlanningModel> {
        return this.http
            .post<ShutdownPlanningModel>(
                MAINTENANCE_SHUTDOWN_URL.PLANNING_SHUTDOWN(),
                shutdownInfo,
                {}
            )
            .pipe(map((res: any) => res.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    private async notifyValidationErrorAndThrow(err: any) {
        const errors = err.error.errors;
        if (errors.length > 0) {
            const errorMessages = errors.map(e => e.message).join(',');
            this.systemMessageService.notifyWarning(errorMessages);
        }
        throw err;
    }

    private async showError(error: any): Promise<void> {
        if (error.error.errors)
            this.systemMessageService.notifyWarning(error.error.errors[0].message);
        else
            this.systemMessageService.notifyError(
                await this.translateService.getTranslation(
                    'MAINTENANCE_SHUTDOWN.XlsxImport.GenericError'
                )
            );
    }

    private castShutdownManagementFormModel(
        model: ShutdownManagementFormModel
    ): ShutdownManagementFormModel {
        if (!model.coiInformations) {
            return model;
        }

        let infoValues = new ShutdownInfoModel();
        infoValues.convertParametersToString(model.coiInformations);
        model.coiInformations = infoValues;

        return model;
    }

    private castMaintenanceShutdownFormResponseModel(
        model: MaintenanceShutdownFormResponseModel
    ): MaintenanceShutdownFormResponseModel {
        if (!model.coiInformations) {
            return model;
        }

        let infoValues = new ShutdownInfoModel();
        infoValues.convertParametersToNumber(model.coiInformations);
        model.coiInformations = infoValues;

        return model;
    }

    public getShutdownProjectDemands(
        maintenanceShutdownId: number
    ): Observable<ProjectDemandModel[]> {
        return this.http
            .get<ProjectDemandModel>(
                MAINTENANCE_SHUTDOWN_URL.GET_SHUTDOWN_PROJECT_DEMANDS(maintenanceShutdownId)
            )
            .pipe(map((res: any) => res.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    public CanCancelShutdown(shutdownId: number): Observable<CanCancelResponseModel> {
        return this.http
            .get<CanCancelResponseModel>(MAINTENANCE_SHUTDOWN_URL.CAN_CANCEL_SHUTDOWN(shutdownId))
            .pipe(map((res: any) => res.data))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(take(1));
    }

    exportNotesLayoutXlsx(): Observable<ShutdownManagementExportModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_NOTES_LAYOUT_MODEL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    exportSystematicsLayoutXlsx(): Observable<ShutdownManagementExportModel> {
        return this.http
            .get(MAINTENANCE_SHUTDOWN_EXPORTS_URL.EXPORT_SYSTEMATICS_LAYOUT_MODEL())
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ShutdownManagementExportModel>) => response.data))
            .pipe(take(1));
    }

    importActivitiesNotes(
        dataToImport: AcitivityNotesImportModel
    ): Observable<ImportActivitiesResponseModel> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_IMPORTS_URL.IMPORT_ACTIVITIES_NOTES(), dataToImport)
            .pipe(
                catchError(err => {
                    return this.systemMessageService.notifyError(err.error.errors[0].message);
                })
            )
            .pipe(map((response: ApiResult<ImportActivitiesResponseModel>) => response.data))
            .pipe(take(1));
    }

    importActivitiesSystematics(
        dataToImport: ActivitiesSystematicsImportModel
    ): Observable<ImportActivitiesResponseModel> {
        return this.http
            .post(MAINTENANCE_SHUTDOWN_IMPORTS_URL.IMPORT_ACTIVITIES_SYSTEMATICS(), dataToImport)
            .pipe(
                catchError(err => {
                    return this.systemMessageService.notifyError(err.error.errors[0].message);
                })
            )
            .pipe(map((response: ApiResult<ImportActivitiesResponseModel>) => response.data))
            .pipe(take(1));
    }
}
