export enum TableColTypes {
    Text,
    TextList,
    Date,
    DateRange,
    Shift,
    Boolean,
    Selection,
    DropdownMenu,
    Remove,
    Toggle,
    Numeric,
    Bookmark,
    Show,
    LargeText,
    TruncateLargeText,
    Percentage,
    ChipText,
    ChipTextBoolean,
    DateWithRangeFilter,
    NumberWithMenuFilter,
}
