<div class="flex-container flex-column gap-16">
    <div class="flex-container space-between align-items-end">
        <div>
            <ng-content select="#tableHeader"></ng-content>
        </div>
        @if (legends) {
            <app-global-color-legend [items]="legends"> </app-global-color-legend>
        }
        <div class="flex-container flex-item justify-end gap-16">
            @if (showCustomizeColumns) {
                <app-customize-table-columns
                    [tableId]="tableId"
                    [tableColumns]="cols"
                    (columnsVisibilityChange)="
                        onColumnsVisibilityChange($event)
                    "></app-customize-table-columns>
            }
            @if (showExportTable) {
                <button
                    pButton
                    pRipple
                    type="button"
                    [label]="'DEFAULT_TEXT.Export' | translate"
                    class="small ghost"
                    icon="pi pi-download"
                    (click)="export()"></button>
            }
        </div>
    </div>
    <p-table
        [id]="tableId"
        [filters]="filter.filterRow ? filter.filterRow : []"
        [value]="data"
        [scrollable]="true"
        [loading]="isLoading"
        styleClass="p-datatable-sm p-datatable-striped"
        [(selection)]="selectedItems"
        (onRowSelect)="onSelectItem()"
        (onRowUnselect)="onSelectItem()"
        [columns]="cols"
        [reorderableColumns]="true"
        (onColReorder)="saveColumnsInCache($event.columns)"
        [resizableColumns]="true"
        columnResizeMode="expand"
        [sortField]="filter.orderBy"
        [sortOrder]="filter.orderByAscending ? 1 : -1">
        <ng-template pTemplate="header" let-columns>
            <tr>
                @for (col of cols; track col) {
                    @if (col.data.visible) {
                        <th
                            id="{{ 'col_' + col.data.field }}"
                            [pTooltip]="col.data.header | translate"
                            tooltipPosition="top"
                            [pSortableColumn]="col.data.field"
                            [pSortableColumnDisabled]="!col.data.sortable"
                            pReorderableColumn
                            pResizableColumn
                            [pReorderableColumnDisabled]="!col.data.reorderable"
                            pFrozenColumn
                            [frozen]="applyFirstStickyColumnClass(col) || col.data.isFrozenColumn"
                            [ngClass]="{
                                'table-col-types-date-range':
                                    col.data.type === TableColTypes.DateRange,
                                center: col.data.type === TableColTypes.Toggle
                            }">
                            @if (col.data.type !== TableColTypes.Selection) {
                                {{ col.data.header | translate }}
                            }
                            @if (col.data.type === TableColTypes.Selection) {
                                <p-tableHeaderCheckbox [disable]="!canEdit" (click)="onSelectAll()">
                                </p-tableHeaderCheckbox>
                            }
                            @if (col.data.sortable) {
                                <p-sortIcon [field]="col.data.field"></p-sortIcon>
                            }
                            @if (col.data.type === TableColTypes.Remove) {
                                <button
                                    p-button
                                    [disable]="!canEdit"
                                    (click)="onRemoveAll()"
                                    class="ghost gray pi pi-minus-circle"></button>
                            }
                        </th>
                    }
                }
            </tr>
            @if (showFilterRow) {
                <tr>
                    @for (col of cols; track col) {
                        <th
                            pFrozenColumn
                            [frozen]="applyFirstStickyColumnClass(col) || col.data.isFrozenColumn">
                            @if (commonFilterTypes?.includes(col.data.type)) {
                                <p-columnFilter
                                    [type]="columnFilterType(col.data.type)"
                                    [matchMode]="getFilterMatchMode(col.data.type)"
                                    [field]="col.data.field"
                                    [showMenu]="false"
                                    [showClearButton]="false"></p-columnFilter>
                            }
                            @if (
                                col.data.type === TableColTypes.Boolean ||
                                col.data.type === TableColTypes.Bookmark ||
                                col.data.type === TableColTypes.Toggle
                            ) {
                                <p-columnFilter
                                    matchMode="equals"
                                    [field]="col.data.field"
                                    [showMenu]="false"
                                    [showClearButton]="false"
                                    ><ng-template
                                        pTemplate="filter"
                                        let-value
                                        let-filter="filterCallback">
                                        <app-dropdown
                                            [drodpwonId]="col.field + 'Filter'"
                                            [ngModel]="value"
                                            [options]="booleanOptions"
                                            optionLabel="label"
                                            optionValue="value"
                                            [placeholder]="'GLOBAL.All' | translate"
                                            [small]="true"
                                            (valueChange)="
                                                filter($event.value)
                                            "></app-dropdown> </ng-template
                                ></p-columnFilter>
                            }
                        </th>
                    }
                </tr>
            }
        </ng-template>
        <ng-template pTemplate="body" let-item let-columns="columns">
            <tr class="list-item">
                @for (col of cols; track col) {
                    @if (col.data.visible) {
                        <td
                            id="{{ 'body_' + col.data.field }}"
                            [class]="
                                'bg-' + backgroundColorFunction({ item, field: col.data.field })
                            "
                            pFrozenColumn
                            [frozen]="applyFirstStickyColumnClass(col) || col.data.isFrozenColumn"
                            [ngClass]="{
                                'table-col-types-date-range':
                                    col.data.type === TableColTypes.DateRange,
                                center: col.data.type === TableColTypes.Toggle,
                                canceled: canceledStyle && canceledStyle(item),
                                'text-align-top': hasLargeTextColumn()
                            }"
                            [pTooltip]="formatTooltip(item, col) | translate"
                            tooltipPosition="top">
                            @switch (col.data.type) {
                                @case (TableColTypes.DropdownMenu) {
                                    <p-menu
                                        #menu
                                        [model]="dropdownActions"
                                        [popup]="true"
                                        appendTo="body">
                                        <ng-template pTemplate="item" let-action>
                                            <a
                                                class="p-menuitem-link flex justify-content-between align-items-center p-3"
                                                (click)="action.callback(item)">
                                                <span class="material-icons p-menuitem-icon">{{
                                                    action.icon
                                                }}</span>
                                                <span> {{ action.label }}</span>
                                            </a>
                                        </ng-template>
                                    </p-menu>
                                    <p-button
                                        class="menu-trigger"
                                        [link]="true"
                                        (click)="menu.toggle($event)"
                                        icon="pi pi-ellipsis-v"></p-button>
                                }

                                @case (TableColTypes.Selection) {
                                    <p-tableCheckbox
                                        [value]="item"
                                        [disabled]="
                                            (isRowSelectable && !isRowSelectable({ data: item })) ||
                                            !canEdit
                                        "></p-tableCheckbox>
                                }

                                @case (TableColTypes.Remove) {
                                    <button
                                        p-button
                                        [disabled]="!canEdit"
                                        (click)="onRemoveItem(item)"
                                        class="ghost gray pi pi-minus-circle"></button>
                                }

                                @case (TableColTypes.Toggle) {
                                    <p-checkbox
                                        [(ngModel)]="item[col.data.field]"
                                        [binary]="true"
                                        (onChange)="
                                            col.data.callbackOnClick({
                                                item,
                                                field: col.data.field
                                            })
                                        "
                                        [disabled]="
                                            (col.data.allowAction !== undefined
                                                ? !col.data.allowAction(item)
                                                : false) || !canEdit
                                        ">
                                    </p-checkbox>
                                }

                                @case (TableColTypes.Date) {
                                    {{
                                        item[col.data.field]
                                            | date: ('primeng.dateTimeFormatGrid' | translate)
                                    }}
                                }

                                @case (TableColTypes.Shift) {
                                    {{ item[col.data.field] | shiftDuration | async }}
                                }

                                @case (TableColTypes.Boolean) {
                                    {{ item[col.data.field] | boolean | async }}
                                }

                                @case (TableColTypes.DateRange) {
                                    {{ getDateRangeString(item[col.data.field]) }}
                                }

                                @case (TableColTypes.LargeText) {
                                    <span class="large-text-cell">{{ item[col.data.field] }}</span>
                                }

                                @case (TableColTypes.Percentage) {
                                    {{
                                        item[col.data.field] !== null
                                            ? item[col.data.field].toFixed(col.data.fixedDigits) +
                                              '%'
                                            : '-'
                                    }}
                                }

                                @default {
                                    {{ item[col.data.field] }}
                                }
                            }
                        </td>
                    }
                }
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [colSpan]="cols.length">
                    {{ 'DEFAULT_TEXT.NoData' | translate }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
