<div class="flex-container flex-column gap-4">
    @if (showInputLabel) {
        <span
            for="selectedItem"
            [class.font-body-sm-regular]="small"
            [class.font-body-md-regular]="!small">
            @if (label) {
                {{ label }}
            } @else {
                {{ placeholder }}
            }

            @if (limitItems) {
                <label>({{ selectedItems?.length ?? 0 }}/{{ maxSelectedItems }})</label>
            }

            @if (required) {
                *
            }

            @if (tooltip) {
                <i class="pi pi-info-circle" [pTooltip]="tooltip"> </i>
            }
        </span>
    }

    <p-multiSelect
        [id]="multiselectId"
        [options]="optionsList"
        [class.small]="small"
        [class.medium]="!small"
        [optionValue]="optionValue"
        [optionLabel]="optionLabel"
        [optionDisabled]="optionDisabled"
        [filter]="true"
        [(ngModel)]="selectedItems"
        [maxSelectedLabels]="1"
        [selectionLimit]="limitItems ? maxSelectedItems : null"
        selectedItemsLabel="{0} {{ 'DEFAULT_TEXT.Selected' | translate }}"
        [placeholder]="'DEFAULT_TEXT.Select' | translate"
        autoWidth="false"
        appendTo="body"
        [disabled]="disabled"
        (onPanelHide)="handlePanelHide()"
        [showClear]="showClear">
    </p-multiSelect>
</div>
