import { environment } from '@environment/environment';

const BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL = `${environment.apiUrl}/maintenance-shutdown/export`;

const EXPORT_LIST = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}`;

const COI_EXPORT = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/coi`;

const EXPORT_LIST_IMPORTED_WITH_ERRORS = (hashFile: string) =>
    `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/errors-rows?hashFile=${hashFile}`;

const EXPORT_ORDERS = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/orders`;

const EXPORT_DEFAULT_IMPORT_MODEL = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/default-model`;

const EXPORT_LIST_REPORT = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/list-report`;

const EXPORT_BATCH_RETURN = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/actual-dates`;

const EXPORT_ORDERS_LAYOUT_MODEL = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/layout/orders`;

const EXPORT_NOTES_LAYOUT_MODEL = () => `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/layout/notes`;

const EXPORT_SYSTEMATICS_LAYOUT_MODEL = () =>
    `${BASE_MAINTENANCE_SHUTDOWN_EXPORTS_URL}/layout/systematics`;

export const MAINTENANCE_SHUTDOWN_EXPORTS_URL = {
    COI_EXPORT,
    EXPORT_LIST,
    EXPORT_LIST_IMPORTED_WITH_ERRORS,
    EXPORT_ORDERS,
    EXPORT_DEFAULT_IMPORT_MODEL,
    EXPORT_LIST_REPORT,
    EXPORT_BATCH_RETURN,
    EXPORT_ORDERS_LAYOUT_MODEL,
    EXPORT_NOTES_LAYOUT_MODEL,
    EXPORT_SYSTEMATICS_LAYOUT_MODEL,
};
