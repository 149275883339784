import { HttpParams } from '@angular/common/http';
import {
    ShutdownManagementFilter,
    ShutdownManagementFilterExportModel,
    ShutdownManagementHistoryFilter,
} from '@app/shared/models/shutdown-management-filter.model';
import { WorkflowMaintenanceShutdownsSearchModel } from '@app/shared/models/workflow-form.model';
import { environment } from '@environment/environment';

const BASE_MAINTENANCE_SHUTDOWN_URL = `${environment.apiUrl}/maintenance-shutdown`;

const buildFilterParams = (filter: ShutdownManagementFilter) => {
    let params = new HttpParams();

    if (filter.planningCenters.length > 0) {
        filter.planningCenters.map(x => (params = params.append('planningCenters', x)));
    }
    if (filter.localizationCenters.length > 0) {
        filter.localizationCenters.map(x => (params = params.append('localizationCenters', x)));
    }
    if (filter.functionalLocationGroup.length > 0) {
        filter.functionalLocationGroup.map(
            x => (params = params.append('functionalLocationGroup', x))
        );
    }
    if (filter.startDate) {
        params = params.append('startDate', filter.startDate.toISOString());
    }
    if (filter.endDate) {
        params = params.append('endDate', filter.endDate.toISOString());
    }
    if (filter.createdBy.length > 0) {
        filter.createdBy.map(x => (params = params.append('creators', x)));
    }
    if (filter.owners.length > 0) {
        filter.owners.map(x => (params = params.append('owners', x)));
    }
    if (filter.name) {
        params = params.append('name', filter.name);
    }
    if (filter.ownerOrAlternate) {
        params = params.append('ownerOrAlternate', filter.ownerOrAlternate);
    }
    if (filter.maintenanceShutdownStatus) {
        params = params.append('status', filter.maintenanceShutdownStatus);
    }
    if (filter.maintenanceShutdownBigShutdown != null) {
        params = params.append('isBigShutdown', filter.maintenanceShutdownBigShutdown);
    }
    if (filter.maintenanceShutdownAdherenceByDateStatus.length > 0) {
        filter.maintenanceShutdownAdherenceByDateStatus.map(
            x => (params = params.append('adherenceByDateStatus', x))
        );
    }
    if (filter.maintenanceShutdownAdherenceByDurationStatus.length > 0) {
        filter.maintenanceShutdownAdherenceByDurationStatus.map(
            x => (params = params.append('adherenceByDurationStatus', x))
        );
    }
    if (filter.workshop) {
        params = params.append('workshop', filter.workshop);
    }
    if (filter.startCreatedDate) {
        params = params.append('startCreatedDate', filter.startCreatedDate.toISOString());
    }
    if (filter.endCreatedDate) {
        params = params.append('endCreatedDate', filter.endCreatedDate.toISOString());
    }
    if (filter.executiveManagement.length > 0) {
        filter.executiveManagement.map(
            x => (params = params.append('executiveManagementOptions', x))
        );
    }
    if (filter.areaManagement.length > 0) {
        filter.areaManagement.map(x => (params = params.append('areaManagementOptions', x)));
    }
    if (filter.phase.length > 0) {
        filter.phase.map(x => (params = params.append('phase', x)));
    }
    if (filter.system.length > 0) {
        filter.system.map(x => (params = params.append('system', x)));
    }
    if (filter.category.length > 0) {
        filter.category.map(x => (params = params.append('category', x)));
    }
    if (filter.filterRow) {
        Object.entries(filter.filterRow)
            .filter(([key, value]) => value.value !== null)
            .map(
                ([key, value]) => (params = params.append(`additionalFilters[${key}]`, value.value))
            );
    }
    if (filter.fullDescription) {
        params = params.append('fullDescription', filter.fullDescription);
    }
    if (filter.hasProjectDemands != null) {
        params = params.append('hasProjectDemands', filter.hasProjectDemands);
    }

    if (filter.maintenanceShutdownIdsToSearch) {
        params = params.append(
            'maintenanceShutdownIdsToSearch',
            filter.maintenanceShutdownIdsToSearch
        );
    }

    return params;
};

const getHttpParamsForExport = (dataToBackend: ShutdownManagementFilterExportModel): HttpParams => {
    let params = buildFilterParams(dataToBackend);

    dataToBackend.addIds?.forEach(element => {
        params = params.append('addIds', element);
    });

    dataToBackend.removeIds?.forEach(element => {
        params = params.append('removeIds', element);
    });

    return params;
};

const ADD = () => BASE_MAINTENANCE_SHUTDOWN_URL;

const GET_BY_FILTER = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/list`;

const GET_BY_FILTER_CALENDAR = () => `${GET_BY_FILTER()}/calendar`;

const GET_GANTT = (filter: ShutdownManagementFilter) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/gantt?${buildFilterParams(filter)}`;

const GET_BY_ID = (maintenanceShutdownId: Number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdownId}`;

const UPDATE = (id: number) => `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}`;

const DELETE = (id: number, justificative: string) => {
    const params = new HttpParams().append('justificative', justificative);
    return `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}?${params.toString()}`;
};

const CHECK_STATUS = (
    shutdownId: number,
    startDate: string,
    endDate: string,
    actualStartDate: string,
    actualEndDate: string
) => {
    const params = new HttpParams()
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('actualStartDate', actualStartDate)
        .set('actualEndDate', actualEndDate);

    const idInPath = shutdownId ? `/${shutdownId}` : '';

    return `${BASE_MAINTENANCE_SHUTDOWN_URL}${idInPath}/check-status?${params.toString()}`;
};

const CHECK_JUSTIFICATION = (shutdownId: number, startDate: string, endDate: string) => {
    const params = new HttpParams().append('newStartDate', startDate).append('newEndDate', endDate);

    return `${BASE_MAINTENANCE_SHUTDOWN_URL}/${shutdownId}/check-justification?${params.toString()}`;
};

const GET_NAMES = (name: string) => {
    const params = new HttpParams().append('name', name);

    return `${BASE_MAINTENANCE_SHUTDOWN_URL}/names?${params.toString()}`;
};

const PLANNING_SHUTDOWN = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/planning`;

const PROFILE = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/profile`;

const CANCEL = (id: number) => `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}/cancel`;

const TOGGLE_BIG_SHUTDOWN = (id: number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}/big-shutdown/toggle`;

const PATCH_OWNER = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/update-owner/`;

const GET_UPDATE_OWNER_REASONS = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/list/update-owner-reasons`;

const GET_OWNER_HISTORY = ({ maintenanceShutdownId }: ShutdownManagementHistoryFilter) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdownId}/owner-history`;

const GET_JUSTIFICATIVES = (maintenanceShutdownId: number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdownId}/justificatives`;

const GET_CHANGE_HISTORY = ({ maintenanceShutdownId }: ShutdownManagementHistoryFilter) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdownId}/change-history`;

const GET_LOGS_DELETED = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/logs/deleted`;

const GET_NON_ADHERENCE_JUSTIFICATIVE_REASON = () =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/list/non-adherence-justificative-reason`;

const GET_PLANNED_ORDERS = (maintenanceShutdonId: number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdonId}/planned-orders`;

const GET_SHUTDOWN_ORDERS = (maintenanceShutdonId: number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${maintenanceShutdonId}/orders`;

const LIST_ADHERENCE_STATUS = () => `${BASE_MAINTENANCE_SHUTDOWN_URL}/adherence-status`;

const GET_REQUESTOR_LIST = (withOthersOption?: boolean) => {
    let params = new HttpParams();

    if (withOthersOption != null) {
        params = params.append('withOthersOption', withOthersOption);
    }

    return `${BASE_MAINTENANCE_SHUTDOWN_URL}/requestor-reason?${params.toString()}`;
};

const GET_SHUTDOWN_PROJECT_DEMANDS = (id: number) =>
    `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}/project-demands`;

const GET_SHUTDOWN_ACTIVITIES = (id: number) => `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}/activities`;

const GET_SHUTDOWN_ACTIVITIES_ONLY_CRITICAL_PATH = (id: number) =>
    `${GET_SHUTDOWN_ACTIVITIES(id)}/only-critical-path`;

const GET_WORKFLOW_MAINTENANCE_SHUTDOWN_LIST = (
    searchData: WorkflowMaintenanceShutdownsSearchModel
) => {
    let params = new HttpParams();

    if (searchData.id) {
        params = params.append('id', searchData.id);
    }

    if (searchData.name) {
        params = params.append('name', searchData.name);
    }

    if (searchData.fullDescription) {
        params = params.append('fullDescription', searchData.fullDescription);
    }

    return `${BASE_MAINTENANCE_SHUTDOWN_URL}/workflow-search?${params.toString()}`;
};

const CAN_CANCEL_SHUTDOWN = (id: number) => `${BASE_MAINTENANCE_SHUTDOWN_URL}/${id}/can-cancel`;

export const MAINTENANCE_SHUTDOWN_URL = {
    GET_BY_FILTER,
    GET_BY_FILTER_CALENDAR,
    GET_GANTT,
    GET_BY_ID,
    GET_NAMES,
    ADD,
    DELETE,
    UPDATE,
    CANCEL,
    TOGGLE_BIG_SHUTDOWN,
    PLANNING_SHUTDOWN,
    PROFILE,
    PATCH_OWNER,
    CHECK_STATUS,
    CHECK_JUSTIFICATION,
    GET_LOGS_DELETED,
    GET_NON_ADHERENCE_JUSTIFICATIVE_REASON,
    GET_PLANNED_ORDERS,
    LIST_ADHERENCE_STATUS,
    GET_UPDATE_OWNER_REASONS,
    GET_OWNER_HISTORY,
    GET_SHUTDOWN_ORDERS,
    GET_JUSTIFICATIVES,
    GET_CHANGE_HISTORY,
    GET_REQUESTOR_LIST,
    GET_SHUTDOWN_PROJECT_DEMANDS,
    GET_SHUTDOWN_ACTIVITIES,
    GET_SHUTDOWN_ACTIVITIES_ONLY_CRITICAL_PATH,
    GET_WORKFLOW_MAINTENANCE_SHUTDOWN_LIST,
    CAN_CANCEL_SHUTDOWN,
};
