import { Injectable } from '@angular/core';

import { TranslateService } from '@app/shared/services/translate.service';
import { MessageService } from 'primeng/api';

import { MessageTypes } from '@utils/enums/message-types.enum';

@Injectable({
    providedIn: 'root',
})
export class SystemMessageService {
    toastKey = 'customToast';

    constructor(
        private messageService: MessageService,
        private translateService: TranslateService
    ) {}

    async notifyInfo(message: string = '') {
        this.messageService.add({
            key: this.toastKey,
            severity: MessageTypes.Info,
            summary: await this.translateService.getTranslation('DEFAULT_TEXT.Attention'),
            detail:
                message == ''
                    ? await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
                    : message,
            icon: 'pi-info-circle',
        });
    }

    async notifySuccess(message: string = '') {
        this.messageService.add({
            key: this.toastKey,
            severity: MessageTypes.Success,
            summary: await this.translateService.getTranslation('DEFAULT_TEXT.Attention'),
            detail:
                message == ''
                    ? await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
                    : message,
            icon: 'pi-check',
        });
    }

    async notifyWarning(message: string) {
        this.messageService.add({
            key: this.toastKey,
            severity: MessageTypes.Warning,
            summary: await this.translateService.getTranslation('DEFAULT_TEXT.Attention'),
            detail: message,
            icon: 'pi-info-circle',
        });
    }

    async notifyError(message: string = '') {
        this.messageService.add({
            key: this.toastKey,
            severity: MessageTypes.Error,
            summary: await this.translateService.getTranslation('DEFAULT_TEXT.Attention'),
            detail:
                message == ''
                    ? await this.translateService.getTranslation('DEFAULT_TEXT.ErrorOperation')
                    : message,
            icon: 'pi-times-circle',
        });
    }

    async notifyErrorAndThrow(err: any, message = '') {
        await this.notifyError(message);
        throw err;
    }

    async notifyMessageErrorAndThrow(err: any): Promise<void> {
        await this.notifyError(err.error.errors[0].message);
        throw err;
    }
}
