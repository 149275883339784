import { Injectable } from '@angular/core';
import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { TableDataUtilsOptions } from '@app/core/utils/enums/table-data-utils-options.enum';
import { FilterMetadata, SelectItem } from 'primeng/api';
import { CustomTableColumnModel } from '../models/global-table.model';
import { PaginatedRequestModel } from '../models/paginated-request.model';
import { SystemMessageService } from './system-message.service';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class TableDataUtilsService {
    constructor(
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService
    ) {}

    // Quando uma coluna é obtida pelo cache ela não possui o metodo equals do CustomTableColumnModel por ser um objeto JSON
    convertCacheObjectToModel(originalCols: CustomTableColumnModel[]): CustomTableColumnModel[] {
        let columnsToReturn: CustomTableColumnModel[] = [];

        originalCols.forEach(({ data }) => {
            columnsToReturn.push(new CustomTableColumnModel(data));
        });

        return columnsToReturn;
    }

    setOrderColumns(
        originalCols: CustomTableColumnModel[],
        cachedCols: CustomTableColumnModel[]
    ): CustomTableColumnModel[] {
        if (cachedCols) {
            cachedCols = this.addOrRemoveColumnsFromCustomColumns(
                originalCols,
                cachedCols,
                TableDataUtilsOptions.Remove
            );
            cachedCols = this.addOrRemoveColumnsFromCustomColumns(
                originalCols,
                cachedCols,
                TableDataUtilsOptions.Add
            );

            originalCols = cachedCols;
        }

        return originalCols.filter(({ data }) => !data.hideColumn);
    }

    setGridOptionsHeaders(filter: PaginatedRequestModel): { [header: string]: string } {
        let paginatedHeaders = new PaginatedRequestModel();
        let headers = {};

        for (const key in paginatedHeaders) {
            if (filter[key] !== null && filter[key] !== undefined && filter[key] !== '') {
                headers = { ...headers, [key]: filter[key].toString() };
            }
        }

        return headers;
    }

    addOrRemoveColumnsFromCustomColumns(
        originalCols: CustomTableColumnModel[],
        cachedCols: CustomTableColumnModel[],
        operation: TableDataUtilsOptions
    ): CustomTableColumnModel[] {
        let index = 0;
        for (const col of cachedCols) {
            const foundedColumn = originalCols.find(defaultCol => col.equals(defaultCol));

            if (!foundedColumn) {
                operation === TableDataUtilsOptions.Add
                    ? cachedCols.splice(index, 0, col)
                    : cachedCols.splice(index, 1);
            } else {
                col.data.allowAction = foundedColumn.data.allowAction;
            }

            index++;
        }

        return cachedCols;
    }

    getFilterRow(filterRow: { [col: string]: FilterMetadata }): { [col: string]: string } {
        let filters = {};

        if (filterRow) {
            Object.keys(filterRow)
                .filter(key => filterRow[key].value !== null)
                .forEach(key => {
                    filters[key] = filterRow[key].value.toString();
                });
        }

        return filters;
    }

    async managePaginatedTableDataError(error: any): Promise<void> {
        if (error.error.errors && error.error.errors[0].title.includes('AdditionalFilters')) {
            this.systemMessageService.notifyWarning(
                await this.translateService.getTranslation('GLOBAL.AdditionalFilterInvalid')
            );
        } else this.systemMessageService.notifyErrorAndThrow(error);
    }

    getCommonFilterTypes(): TableColTypes[] {
        return [
            TableColTypes.Text,
            TableColTypes.TextList,
            TableColTypes.Date,
            TableColTypes.DateRange,
            TableColTypes.Shift,
            TableColTypes.Numeric,
            TableColTypes.LargeText,
            TableColTypes.TruncateLargeText,
            TableColTypes.Percentage,
            TableColTypes.ChipText,
        ];
    }

    getBooleanFilterTypes(): TableColTypes[] {
        return [
            TableColTypes.Boolean,
            TableColTypes.Bookmark,
            TableColTypes.Toggle,
            TableColTypes.ChipTextBoolean,
        ];
    }

    async getMatchModeOptions(): Promise<SelectItem[]> {
        return [
            {
                value: 'GreaterThan',
                label: await this.translateService.getTranslation('primeng.gte'),
            },
            {
                value: 'LowerThan',
                label: await this.translateService.getTranslation('primeng.lte'),
            },
        ];
    }
}
